import Popover from "@paljs/ui/Popover";
import { Card, CardHeader, CardBody, CardFooter } from "@paljs/ui/Card";
import { Chat, ChatMessages } from "@paljs/ui/Chat";
import React from "react";
import moment from "moment";
import styled from "styled-components";
import Col from "@paljs/ui/Col";
import { Button } from "@paljs/ui/Button";
import { InputGroup } from "@paljs/ui/Input";
import Spinner from "@paljs/ui/Spinner";
import Fetch from "../Fetch";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { STATUS_ORDER_SUPPORT } from "../../constants";

const ContentButton = styled(Col)`
  padding-top: 10px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const RowBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CardStyle = styled(Card)`
  margin-bottom: 0px;
`;

const ConfirmationCancel = (props) => {
  const buttonOutRef = React.useRef();
  const [text, setText] = React.useState();

  return (
    <>
      <button ref={buttonOutRef} style={{ display: "none" }}></button>
      <Popover
        className="inline-block"
        trigger="click"
        placement="top"
        overlay={
          <>
            <CardStyle style={{ marginBottom: 0 }}>
              <CardHeader>
                <InputGroup fullWidth>
                  <textarea
                    placeholder="Qual a melhor data e horário para você?"
                    rows={3}
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                  />
                </InputGroup>
              </CardHeader>
              <CardBody>
                <RowBetween className="popover-card">
                  <Button
                    status="Danger"
                    size="Small"
                    onClick={() => {
                      if (buttonOutRef.current) buttonOutRef.current.click();
                      props.onSave(false, text);
                    }}
                  >
                    Prosseguir
                  </Button>
                </RowBetween>
              </CardBody>
            </CardStyle>
          </>
        }
      >
        <Button size="Small" status="Danger" style={{ marginLeft: 25 }}>
          Não
        </Button>
      </Popover>
    </>
  );
};

export default function ChatHistory(props) {
  const { order } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const tokenDecoded = jwt_decode(localStorage.getItem("token"));

  const onSave = (accepted, text = "") => {
    setIsLoading(true);

    const data = {
      description: accepted ? "Sim" : text || "Não",
      idOrder: order.id,
      accepted,
    };

    Fetch.post("/tracking/history", data)
      .then((response) => {
        toast.success("Salvo com sucesso!");
        setMessages([
          ...messages,
          {
            message: data.description,
            sender: tokenDecoded?.nameUser,
            date: moment().format("DD/MM/YYYY HH:mm"),
            type: "text",
            reply: true,
          },
        ]);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  React.useLayoutEffect(() => {
    const messages = order?.historyOrder?.map((x) => ({
      message: x.description,
      date: moment(x.createdAt).format("DD/MM/YYYY HH:mm"),
      type: "text",
      sender: tokenDecoded?.id == x.idUser ? "Você" : x.nameUser,
      avatar: require("../../assets/images/logo.png"),
      reply: tokenDecoded?.id == x.idUser,
      dateSchedule: x.dateSchedule
    }));
    setMessages(messages);
  }, [order]);

  if (isLoading)
    return (
      <>
        <Spinner />
      </>
    );

  return (
    <>
      {!!messages?.length && (
        <CardFooter style={{ padding: 0 }}>
          <Chat status="Basic" title="Histórico" size="Small">
            <ChatMessages messages={messages} />
            {!!messages[messages.length - 1].dateSchedule &&
              (order?.status ==
                STATUS_ORDER_SUPPORT.WAITING_SCHEDULE) && (
                <ContentButton>
                  <Button
                    onClick={() => onSave(true)}
                    size="Small"
                    status="Success"
                  >
                    {" "}
                    Sim{" "}
                  </Button>

                  <ConfirmationCancel onSave={onSave} />
                </ContentButton>
              )}
          </Chat>
        </CardFooter>
      )}
    </>
  );
}
