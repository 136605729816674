export const STATUS_ORDER_SUPPORT = {
  OPEN: 'open',
  ATTENDANCE: 'attendance',
  MAINTENANCE: 'maintenance',
  WAITING_MAINTENANCE: "waiting_maintenance",
  ANALYSIS_MAINTENANCE: "analysis_maintenance",
  CLOSED: 'closed',
  SCHEDULE: 'schedule',
  WAITING_SCHEDULE: 'waiting_schedule',
  FINISHED_MAINTENANCE: "finished_maintenance",
};
