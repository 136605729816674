import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Select from "@paljs/ui/Select";
import { LayoutHeader } from "@paljs/ui/Layout";
import { EvaIcon } from "@paljs/ui/Icon";
import { breakpointDown } from "@paljs/ui/breakpoints";
import { Actions } from "@paljs/ui/Actions";
import SettingsContext from "../Settings";

const HeaderStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${breakpointDown("sm")`
    .right{
      margin-right: -15px;
    }
  `}
  .right > div {
    height: auto;
    display: flex;
    align-content: center;
  }
  .logo {
    font-size: 1.25rem;
    white-space: nowrap;
    text-decoration: none;
  }
`;

const Label = styled.span`
  display: flex;
  align-items: center;
`;

const SelectStyled = styled(Select)`
  min-width: 150px;
`;

const Header = (props) => {
  const logout = async () => {};

  const { settings, saveSettings } = React.useContext(SettingsContext);

  const themeOptions = [
    {
      value: "default",
      label: (
        <Label>
          <EvaIcon name="droplet" options={{ fill: "#a6c1ff" }} />
          Padrão
        </Label>
      ),
    },
    {
      value: "dark",
      label: (
        <Label>
          <EvaIcon name="droplet" options={{ fill: "#192038" }} />
          Escuro
        </Label>
      ),
    },
    {
      value: "cosmic",
      label: (
        <Label>
          <EvaIcon name="droplet" options={{ fill: "#5a37b8" }} />
          Cósmico
        </Label>
      ),
    },
    {
      value: "corporate",
      label: (
        <Label>
          <EvaIcon name="droplet" options={{ fill: "#3366ff" }} />
          Claro
        </Label>
      ),
      selected: true,
    },
  ];
  return (
    <LayoutHeader fixed>
      <HeaderStyle>
        <Actions
          size="Medium"
          actions={[
            {
              content: (
                <a tag={Link} href="/">
                  <img src={require('../../assets/images/logo.png')} height={35}  alt="logo"/>
                </a>
              ),
            },
            
          ]}
        />
        <Actions
          size="Small"
          className="right"
          actions={[
            {
                content: (
                  <SelectStyled
                    isSearchable={false}
                    shape="SemiRound"
                    placeholder="Themes"
                    value={themeOptions.find(
                      (item) => item.value === settings.theme
                    )}
                    options={themeOptions}
                    onChange={({ value }) => saveSettings({ theme: value })}
                  />
                ),
              },
          ]}
        />
      </HeaderStyle>
    </LayoutHeader>
  );
};
export default Header;
