import React from "react";
import { Button } from "@paljs/ui/Button";
import Alert from "@paljs/ui/Alert";
import styled from "styled-components";
import Col from "@paljs/ui/Col";

const AlertStyle = styled(Alert)`
  text-align: center;
`;

const AttendanceInfo = (props) => {
  const id = new URL(window.location.href).searchParams.get("id");

  return (
    <>
      <div className="col-flex-center">
        <Col breakPoint={{ lg: 4, md: 4, sm: 12 }}>
          <AlertStyle status="Primary">
            Atendimento criado com sucesso!
            <br />
            <br />
            <strong>Atendimento: {id?.toString().padStart(5, "0")}</strong>
            <br />
            Enviamos um email com sua senha para você acompanhar o atendimento
          </AlertStyle>
          <Button
            style={{ marginTop: 20 }}
            fullWidth
            status="Success"
            onClick={() => props.history.push("/")}
          >
            Ok
          </Button>
        </Col>
      </div>
    </>
  );
};
export default AttendanceInfo;
