import * as React from "react";
import { Card, CardBody } from "@paljs/ui/Card";
import { Button } from "@paljs/ui/Button";
import Lottie from "react-lottie";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import styled from "styled-components";

const Title = styled.span`
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Text = styled.span`
  text-align: center;
`;

const ContainerText = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 120px;
`;

const Home = (props) => {
  React.useEffect(() => {
    localStorage.setItem("token", "");
  }, []);

  const options = {
    loop: true,
    autoplay: true,
    animationData: require(`../assets/lotties/new-attendance.json`),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const optionsFollow = {
    loop: true,
    autoplay: true,
    animationData: require(`../assets/lotties/follow-attendance.json`),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Row>
      <Col breakPoint={{ md: 4, sm: 12 }}>
        <Card>
          <CardBody>
            <ContainerText>
              <Title>Missão</Title>
              <Text>
                {
                  " Entregar para o cliente o que foi contratado."
                }
              </Text>
            </ContainerText>
          </CardBody>
        </Card>
      </Col>
      <Col breakPoint={{ md: 4, sm: 12 }}>
        <Card>
          <CardBody>
            <ContainerText>
              <Title>Visão</Title>
              <Text>
                {
                  " Projetar, executar e vender mais de 1.000 imóveis até 2030."
                }
              </Text>
            </ContainerText>
          </CardBody>
        </Card>
      </Col>
      <Col breakPoint={{ md: 4, sm: 12 }}>
        <Card>
          <CardBody>
            <ContainerText>
              <Title>Valores</Title>
              <Text>{" Transparência nas negociações."}</Text>
              <Text>{" Proximidade com nossos clientes."}</Text>
              <Text>{" Desenvolver os melhores colaboradores e fornecedores."}</Text>
            </ContainerText>
          </CardBody>
        </Card>
      </Col>
      <Col breakPoint={{ md: 6, sm: 12 }}>
        <Card>
          <CardBody className="figure-card">
            <Lottie
              width={190}
              height={190}
              options={options}
              isPaused={false}
              isStopped={false}
            />
          </CardBody>
          <Button
            status="Success"
            onClick={() => props.history.push("/newsupport")}
          >
            Iniciar atendimento
          </Button>
        </Card>
      </Col>
      <Col breakPoint={{ md: 6, sm: 12 }}>
        <Card>
          <CardBody className="figure-card">
            <Lottie
              width={250}
              height={250}
              options={optionsFollow}
              isPaused={false}
              isStopped={false}
              style={{ marginBottom: -55 }}
            />
          </CardBody>
          <Button onClick={() => props.history.push("/login")}>
            Acompanhar atendimento
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default Home;
