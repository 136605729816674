import React from "react";
import { Card, CardBody } from "@paljs/ui/Card";
import { IconCircle } from "../IconCircle";
import { LoadingCard } from "../Loading/LoadingCard";
import { Button } from "@paljs/ui/Button";
import Col from "@paljs/ui/Col";
import { formatterMbKb } from "../Utils";

const CardUploadFile = (props) => {
  const { file, onRemoveFile } = props;

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="col-flex-center">
            <IconCircle
              backgroundColor="#D6E4FF"
              style={{
                height: 40,
                width: 40,
              }}
            >
              <svg
                style={{
                  color: "#0c79d8",
                  width: 23,
                  height: 23,
                }}
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="file"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="svg-inline--fa fa-file fa-w-12 fa-3x"
              >
                <path
                  fill="currentColor"
                  d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z"
                  className=""
                ></path>
              </svg>
            </IconCircle>
          </div>
          <div className="col-flex-center">
            <span className="ml-2 mr-2 text-file-name">{file.path}</span>
            <span style={{ fontSize: 9 }}>
              {formatterMbKb(file?.size)}
            </span>
          </div>
        </CardBody>
          <Button
            status="Danger"
            size="Small"
            onClick={onRemoveFile}
          >
            Excluir
          </Button>
      </Card>
    </React.Fragment>
  );
};

export { CardUploadFile };
