import React from "react";

export const IconCircle = (props) => {
  return (
    <React.Fragment>
      <div
        onClick={props.onClick}
        className={`icon-circle ${!!props.onClick ? "card-clickable" : ""}`}
        style={{ backgroundColor: props.backgroundColor, ...props.style }}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
};