import React from "react";
import { Button } from "@paljs/ui/Button";
import { EvaIcon } from "@paljs/ui/Icon";
import { InputGroup } from "@paljs/ui/Input";
import moment from "moment";
import { Card, CardBody, CardHeader, CardFooter } from "@paljs/ui/Card";
import Badge from "@paljs/ui/Badge";
import styled from "styled-components";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import { STATUS_ORDER_SUPPORT } from "../../constants";
import { LoadingCard } from "../Loading/LoadingCard";
import Fetch from "../Fetch";
import { toast } from "react-toastify";
import ChatHistory from "../ChatHistory";

const Strong = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

const Title = styled.span`
  font-size: 12px;
  margin-right: 10px;
`;

const RowText = styled(Row)`
  margin-top: 7px;
  margin-bottom: 8px;
`;

const TitleFooter = styled.span`
  font-size: 13px;
  margin-bottom: 10px;
`;

const Input = styled(InputGroup)`
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
`;

const OrderRequest = (props) => {
  const { order } = props;

  const [note, setNote] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [isSaving, setIsSaving] = React.useState(false);
  const [isShowFeedback, setShowFeedback] = React.useState(true);

  const statusBadge = (status) => {
    switch (status) {
      case STATUS_ORDER_SUPPORT.OPEN:
        return { status: "Warning", text: "Aberto" };
      case STATUS_ORDER_SUPPORT.WAITING_MAINTENANCE:
      case STATUS_ORDER_SUPPORT.ANALYSIS_MAINTENANCE:
        return { status: "Danger", text: "Aguardando manutenção" };
      case STATUS_ORDER_SUPPORT.WAITING_SCHEDULE:
        return { status: "Info", text: "Aguardando agendamento" };
      case STATUS_ORDER_SUPPORT.MAINTENANCE:
        return { status: "Danger", text: "Em manutenção" };
      case STATUS_ORDER_SUPPORT.ATTENDANCE:
        return { status: "Primary", text: "Em atendimento" };
      case STATUS_ORDER_SUPPORT.CLOSED:
        return { status: "Success", text: "Encerrado" };
      case STATUS_ORDER_SUPPORT.FINISHED_MAINTENANCE:
        return { status: "Success", text: "Manutenção finalizada" };
      default:
        return { status: "Ligth", text: "Não definido" };
    }
  };

  const orderStatus = statusBadge(order.status);

  const savingFeedback = () => {
    setIsSaving(true);
    Fetch.post("/feedback", { note, description, orderId: order.id })
      .then((response) => {
        toast.success("Avaliado com sucesso!");
        setIsSaving(false);
        setShowFeedback(false);
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Atendimento: {order.id.toString().padStart(5, "0")}</span>
            <div>
              <Badge
                style={{ position: "relative" }}
                status={orderStatus.status}
              >
                {orderStatus.text}
              </Badge>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Col className="col-center">
            <RowText>
              <Title>Empreendimento:</Title>
              <Strong>
                {order.building
                  ? `${order.building.name} ${
                      order.building.district || ""
                    } - ${order.building.city}`
                  : " - "}
              </Strong>
            </RowText>
            <RowText>
              <Title>Unidade:</Title>
              <Strong>
                {order.unit
                  ? `${order.unit.number}${
                      order.unit.floor ? `, ${order.unit.floor}` : ""
                    }${order.unit.block ? `, ${order.unit.block}` : ""}`
                  : " - "}
              </Strong>
            </RowText>
            <RowText>
              <Title>Problema:</Title>
              <Strong>
                {order.typeProblem ? order.typeProblem.description : " - "}
              </Strong>
            </RowText>
            <RowText>
              <Title>Aberto em:</Title>
              <Strong>
                {moment(order.createdAt).format("DD/MM/YYYY HH:mm")}
              </Strong>
            </RowText>
            {order.status == STATUS_ORDER_SUPPORT.CLOSED && !!order.closedAt && (
              <RowText>
                <Title>Encerrado em:</Title>
                <Strong>
                  {moment(order.closedAt).format("DD/MM/YYYY HH:mm")}
                </Strong>
              </RowText>
            )}
            <RowText>
              <Strong>{order.description}</Strong>
            </RowText>
            {!!order.files && (
              <RowText>
                <Title>Imagens e anexos:</Title>
                <Strong>{`${order.files}`}</Strong>
              </RowText>
            )}
          </Col>
        </CardBody>
        <ChatHistory order={order} />
        {order.status == STATUS_ORDER_SUPPORT.CLOSED &&
          isShowFeedback &&
          (!order.feedbacks || !order.feedbacks.length) && (
            <CardFooter>
              <Col className="col-flex-center" style={{ minHeight: 30 }}>
                <LoadingCard isLoading={isSaving}>
                  <TitleFooter>Avaliar serviço e atendimento</TitleFooter>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button appearance="ghost" onClick={() => setNote(1)}>
                      <div style={{ marginLeft: -10, marginTop: -10 }}>
                        <EvaIcon
                          name={note >= 1 ? "star" : "star-outline"}
                          options={{
                            fill: "#ffcb2b",
                            width: 30,
                            height: 30,
                          }}
                        />
                      </div>
                    </Button>
                    <Button appearance="ghost" onClick={() => setNote(2)}>
                      <div style={{ marginLeft: -10, marginTop: -10 }}>
                        <EvaIcon
                          name={note >= 2 ? "star" : "star-outline"}
                          options={{
                            fill: "#ffcb2b",
                            width: 30,
                            height: 30,
                          }}
                        />
                      </div>
                    </Button>

                    <Button appearance="ghost" onClick={() => setNote(3)}>
                      <div style={{ marginLeft: -10, marginTop: -10 }}>
                        <EvaIcon
                          name={note >= 3 ? "star" : "star-outline"}
                          options={{
                            fill: "#ffcb2b",
                            width: 30,
                            height: 30,
                          }}
                        />
                      </div>
                    </Button>

                    <Button appearance="ghost" onClick={() => setNote(4)}>
                      <div style={{ marginLeft: -10, marginTop: -10 }}>
                        <EvaIcon
                          name={note >= 4 ? "star" : "star-outline"}
                          options={{
                            fill: "#ffcb2b",
                            width: 30,
                            height: 30,
                          }}
                        />
                      </div>
                    </Button>

                    <Button
                      appearance="ghost"
                      onClick={() => setNote(5)}
                      href="#"
                    >
                      <div style={{ marginLeft: -10, marginTop: -10 }}>
                        <EvaIcon
                          name={note >= 5 ? "star" : "star-outline"}
                          options={{
                            fill: "#ffcb2b",
                            width: 30,
                            height: 30,
                          }}
                        />
                      </div>
                    </Button>
                  </Row>
                  <Input fullWidth>
                    <textarea
                      placeholder="Comente aqui (Opcional)"
                      maxLength={3000}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      rows={2}
                    />
                  </Input>
                  <Button
                    status="Success"
                    shape="SemiRound"
                    size="Small"
                    onClick={savingFeedback}
                  >
                    <span>Salvar avaliação</span>
                  </Button>
                </LoadingCard>
              </Col>
            </CardFooter>
          )}
      </Card>
    </>
  );
};

export { OrderRequest };
