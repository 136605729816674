import React from 'react';
import Select from '@paljs/ui/Select';
import { useFetch } from '../Fetch';

export default function SelectUnit({ building, onChange, value }) {
  
  const { isLoading, reFetch, data } = useFetch(`/publicdata/unit?buildingId=${building}`, { method: 'get' });

  React.useEffect(() => {
    reFetch();
  }, [building])

  const optionsUnity =
    data && data.length
      ? data.map((x) => ({
          value: x.id,
          label: `${x.number}${x.floor ? `, ${x.floor}` : ''}${x.block ? `, ${x.block}` : ''}`,
        }))
      : [];

  return (
    <React.Fragment>
      <Select options={optionsUnity} placeholder="Unidade" isLoading={isLoading} onChange={onChange} value={value} noOptionsMessage={() => !building ? 'Selecione primeiro o empreendimento' : ''} />
    </React.Fragment>
  );
}
