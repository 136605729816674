import * as React from "react";
import { Card, CardBody, CardHeader } from "@paljs/ui/Card";
import { Button } from "@paljs/ui/Button";
import { InputGroup } from "@paljs/ui/Input";
import SelectBuilding from "../components/Select/SelectBuilding";
import SelectUnit from "../components/Select/SelectUnit";
import SelectTypeProblem from "../components/Select/SelectTypeProblem";
import ReCAPTCHA from "react-google-recaptcha";
import { Checkbox } from "@paljs/ui/Checkbox";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import Dropzone from "react-dropzone";
import { DropInputIconFile } from "../components/Dropzone";
import { toast } from "react-toastify";
import { CardUploadFile } from "../components/Cards/Upload";
import InputMask from "react-input-mask";
import styled, { css } from "styled-components";
import Fetch from "../components/Fetch";
import { LoadingCard } from "../components/Loading/LoadingCard";
import { formatterMbKb } from "../components/Utils";

const DropContainer = styled.div`
  ${({ theme }) => css`
    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: ${theme.borderBasicColor4};
      border-style: dashed;
      background-color: ${theme.backgroundBasicColor2};
      color: ${theme.textHintColor};
      outline: none;
      transition: border 0.24s ease-in-out;
    }

    .dropzone:focus {
      border-color: #2196f3;
    }

    .drop-image {
      justify-content: center;
      border-radius: 12px;
    }
  `}
`;

const maxSizeFile = process.env.REACT_APP_MAX_SIZE_FILE_BYTES;

const Input = styled(InputGroup)`
  margin-bottom: 20px;
`;

const RequestAttendance = (props) => {
  const recaptchRef = React.useRef();

  const [unit, setUnit] = React.useState(undefined);
  const [building, setBuilding] = React.useState(undefined);
  const [typeProblem, setProblem] = React.useState(undefined);
  const [terms, setTerms] = React.useState(false);
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [reCaptcha, setReCaptcha] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    localStorage.setItem('token', '')
  }, [])

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const fileMoreSize = acceptedFiles.find((x) => x.size > maxSizeFile);
      if (!!fileMoreSize) {
        toast.warn(
          `Arquivo ${fileMoreSize.name} excedeu o máximo permitido de ${formatterMbKb(maxSizeFile)}`
        );
        return;
      }
      const filesToAdd = files.concat(acceptedFiles);
      const totalSize = filesToAdd.reduce((a, b) => a + b.size, 0);
      if (totalSize > maxSizeFile) {
        toast.warn(
          `A soma dos arquivos ultrapassam o máximo permitido de ${formatterMbKb(maxSizeFile)}. Remova um arquivo ou adicione um arquivo menor`
        );
        return;
      }

      setFiles(filesToAdd);
    }
  };

  const onRemoveFile = (index) => {
    setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
  };

  const disabled =
    isLoading ||
    !name ||
    !email ||
    !building ||
    !unit ||
    !typeProblem ||
    !description ||
    !reCaptcha ||
    !terms;

  const onSend = async () => {
    const dataSend = {
      name,
      email,
      phone,
      building,
      unit,
      typeProblem,
      description,
      reCaptcha,
      terms,
      files: files && files.length ? files.map(x => x.name) : [],
      urlTerms: `${window.location.origin}/terms`,
    };

    setIsLoading(true);
    try {
      const response = await Fetch.post("/order", dataSend);
      if (files && files.length) {
        files.forEach((fileToSave) => {
          const data = new FormData();
          data.append("file", fileToSave);
          Fetch.postToken(`/file/upload?directory=support&idOrder=${response.data.data.id}`, data, response.data.data.token)
            .then((response) => {})
            .catch((error) => {});
        });
      }
      setIsLoading(false);
      toast.success('Atendimento enviado com sucesso! Verifique seu email');
      props.history.push(`/info?id=${response.data.data.id}`);
    } catch (e) {
      setIsLoading(false);
      if (recaptchRef.current) recaptchRef.current.reset();
    }
  };

  return (
    <React.Fragment>
      <LoadingCard isLoading={isLoading}>
        <Card>
          <CardHeader>Nova solicitação de atendimento</CardHeader>
          <CardBody>
            <Col breakPoint={{ lg: 12, md: 12 }}>
              <Row>
                <Col breakPoint={{ md: 6 }}>
                  <Input fullWidth>
                    <input
                      type="text"
                      placeholder="Seu nome"
                      maxLength={150}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ md: 6 }}>
                  <Input fullWidth>
                    <input
                      type="email"
                      placeholder="Seu email"
                      maxLength={150}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ md: 6 }}>
                  <Input fullWidth>
                    <InputMask
                      mask="(99) 99999 - 9999"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    >
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          placeholder="Seu celular para contato"
                          type="tel"
                        />
                      )}
                    </InputMask>
                  </Input>
                </Col>

                <Col breakPoint={{ md: 6 }}>
                  <Input fullWidth>
                    <div style={{ width: "100%" }}>
                      <SelectBuilding
                        onChange={(value) => setBuilding(value)}
                        value={building}
                      />
                    </div>
                  </Input>
                </Col>

                <Col breakPoint={{ md: 6 }}>
                  <Input fullWidth>
                    <div style={{ width: "100%" }}>
                      <SelectUnit
                        building={building ? building.value : 0}
                        onChange={(value) => setUnit(value)}
                        value={unit}
                      />
                    </div>
                  </Input>
                </Col>

                <Col breakPoint={{ md: 6 }}>
                  <Input fullWidth>
                    <div style={{ width: "100%" }}>
                      <SelectTypeProblem
                        onChange={(value) => setProblem(value)}
                        value={typeProblem}
                      />
                    </div>
                  </Input>
                </Col>

                <Col breakPoint={{ md: 12 }}>
                  <Input fullWidth>
                    <textarea
                      placeholder="Descreva os detalhes"
                      maxLength={3000}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      rows={5}
                    />
                  </Input>
                </Col>

                <Col breakPoint={{ md: 12 }}>
                  <DropContainer>
                    <Dropzone multiple onDrop={onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="mb-3">
                          <DropInputIconFile
                            getRootProps={getRootProps}
                            getInputProps={getInputProps}
                          />
                          {!!files && !!files.length && (
                            <Row style={{ marginTop: 20 }}>
                              {files.map((file, i) => (
                                <Col key={i} breakPoint={{ md: 2 }}>
                                  <CardUploadFile
                                    file={file}
                                    onRemoveFile={() => onRemoveFile(i)}
                                    isLoading={false}
                                  />
                                </Col>
                              ))}
                            </Row>
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </DropContainer>
                </Col>

                <Col
                  breakPoint={{ md: 12 }}
                  style={{
                    marginBottom: 20,
                    marginTop: files && files.length ? 0 : 20,
                  }}
                >
                  <Checkbox
                    checked={terms}
                    onChange={(value) => setTerms(!terms)}
                  >
                    Li e aceito todos os nossos{" "}
                    <a href="/terms" target="_blank">
                      Termos & Condições
                    </a>
                {" "}e a nossa{" "}
                    <a href="/policy" target="_blank">
                      Política de privacidade
                    </a>.
                  </Checkbox>
                </Col>

                <Col breakPoint={{ md: 6 }}>
                  <Input fullWidth>
                    <ReCAPTCHA
                      onChange={(value) => setReCaptcha(value)}
                      size="normal"
                      sitekey={"6LfgQrQZAAAAAPVaNpYISG9b1NjV3oVNUUfGEvIR"}
                      ref={recaptchRef}
                    />
                  </Input>
                </Col>
              </Row>
              <Button
                disabled={disabled}
                status="Success"
                shape="SemiRound"
                onClick={onSend}
              >
                <span>Enviar</span>
              </Button>
            </Col>
          </CardBody>
        </Card>
      </LoadingCard>
    </React.Fragment>
  );
};

export default RequestAttendance;
