import React from "react";

export default function Terms() {
  return (
    <React.Fragment>
      <div style={{ padding: 25 }}>
        <h2>Termos &amp; Condições</h2>
        <p>
          Ao acessar o portal de atendimento Tatacon, esses termos serão
          aplicados automaticamente a você. Por isso, certifique-se de lê-los
          com atenção antes de usar. Você não tem permissão para copiar ou
          modificar a aplicação, qualquer parte da aplicação ou nossas marcas
          registradas de forma alguma. Você não tem permissão para tentar
          extrair o código-fonte da aplicação e também não deve tentar traduzir
          a aplicação para outros idiomas ou criar versões derivadas. O própria
          aplicação e todas as marcas registradas, direitos autorais e outros
          direitos de propriedade intelectual relacionados a ele pertencem à
          TATACON CONSTRUTORA LTDA sob CNPJ 10.1765.66/0001-22.
        </p>
        <p>
          A TATACON CONSTRUTORA LTDA está empenhada em garantir que a aplicação
          seja o mais útil e eficiente possível. Por esse motivo, reservamo-nos
          o direito de fazer alterações na aplicação ou cobrar por seus
          serviços, a qualquer momento e por qualquer motivo. Nunca cobraremos
          pela aplicação nem pelos serviços sem deixar claro para você
          exatamente o que você está pagando.
        </p>
        <p>
          Ao utilizar o portal de atendimento e solicitar um novo atendimento
          você concorda que contratou algum produto ou serviço da TATACON
          CONSTRUTORA LTDA e concorda em informar corretamente seus dados
          pessoais e no compartilhamento dos dados pessoais e da solicitação com
          o SIoT - Atendimento representado pela KONZTEC TECNOLOGIA LTDA
          inscrita sob CNPJ 31.599.316/0001-75. Os dados pessoais preenchido são
          nome, telefone e email. Estes dados serão utilizados para
          identificação e para que a TATACON CONSTRUTORA LTDA possa estar
          entrando em contato com você. Os dados de compartilhamento são todos
          os informados ao abrir um nova solicitação e você concorda em
          compartilhar os dados informados com a KONZTEC TECNOLOGIA LTDA.
        </p>
        <p>
          A TATACON CONSTRUTORA LTDA se reserva a poder encerrar a sua conta,
          caso identifique abuso, conteúdo impróprio, ofensivo ou violação na
          integridade da aplicação . Cabe a TATACON CONSTRUTORA LTDA definir os
          critérios para abuso, conteúdo impróprio, ofensivo e violação na
          integridade.
        </p>
        <p>
          <strong>Alterações para estes Termos e Condições</strong>
        </p>
        <p>
          Podemos atualizar nossos Termos e Condições de tempos em tempos.
          Assim, você é aconselhado para rever esta página periodicamente para
          quaisquer alterações. Vamos notificá-lo de qualquer alterações,
          publicando os novos Termos e Condições nesta página. Essas alterações
          são efetivas imediatamente depois que eles são publicados nesta
          página.
        </p>
        <p>
          <strong>Contate nos</strong>
        </p>
        <p>
          Se você tiver dúvidas ou sugestões sobre nossos Termos e Condições,
          não hesite em entrar em contato conosco.
        </p>
      </div>
    </React.Fragment>
  );
}
