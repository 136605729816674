import React from "react";
import { Button } from "@paljs/ui/Button";
import { InputGroup } from "@paljs/ui/Input";
import Col from "@paljs/ui/Col";
import { Card, CardBody } from "@paljs/ui/Card";
import styled, { css } from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { LoadingCard } from "../components/Loading/LoadingCard";
import Fetch from "../components/Fetch";
import { toast } from "react-toastify";

const Input = styled(InputGroup)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Image = styled.img`
  height: 100px;
  margin-left: -10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const CenterRow = styled.div`
  display: flex;
  flex-direction: column;
  justifiy-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Login = (props) => {
  const recaptchRef = React.useRef();

  const [reCaptcha, setReCaptcha] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    localStorage.setItem('token', '')
  }, [])

  const onEnter = () => {
    if (!email) {
      toast.warn("Informe o email");
      return;
    }
    if (!password) {
      toast.warn("Informe a senha");
      return;
    }
    if (!reCaptcha) {
      toast.warn("Marque que você não é um robô");
      return;
    }
    setIsLoading(true);
    Fetch.post("/auth", { email: email?.replace(/ /g, ""), password, reCaptcha })
      .then((response) => {
        setIsLoading(false);
        localStorage.setItem("token", response.data.token);
        props.history.push("tracking");
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="col-flex-center">
        <Col breakPoint={{ md: 4, lg: 4, sm: 12 }}>
          <LoadingCard isLoading={isLoading}>
            <Card>
              <CardBody>
                <CenterRow>
                  <Image
                    src={require("../assets/images/logo.png")}
                    alt="Logo_Tatacon"
                  />
                </CenterRow>
                <Input fullWidth>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Input>
                <Input fullWidth>
                  <input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Input>

                <CenterRow>
                  <ReCAPTCHA
                    onChange={(value) => setReCaptcha(value)}
                    size="normal"
                    sitekey={"6LfgQrQZAAAAAPVaNpYISG9b1NjV3oVNUUfGEvIR"}
                    ref={recaptchRef}
                  />
                </CenterRow>
                <Button
                  status="Success"
                  type="button"
                  shape="SemiRound"
                  fullWidth
                  onClick={onEnter}
                >
                  Acessar
                </Button>
              </CardBody>
            </Card>
          </LoadingCard>
        </Col>
      </div>
    </>
  );
};
export default Login;
