import React from "react";
import axios from "axios";
import { toast } from "react-toastify";

const baseUserURL = process.env.REACT_APP_URI_BASE;
const keyPublicRouter = process.env.REACT_APP_KEY_PUBLIC_ROUTER;

const useFetch = (url, options = { method: "get", data: undefined }) => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    const headers = {
      token: keyPublicRouter,
    };

    try {
      const instance = axios.create({
        baseURL: baseUserURL,
        timeout: 50000,
        headers,
      });

      instance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            toast.error("Sessão expirada. Faça login novamente");
            localStorage.setItem("token", "");
            localStorage.setItem("user", "");
            window.location.href = `${window.location.origin}/login`;
          } else if (error.response && error.response.status === 403) {
            toast.warn("Usuário não tem permissão");
          } else if (error.response && error.response.status === 429) {
            toast.warn("Muitas requisições ao mesmo tempo");
          } else if (error.response && error.response.status === 400) {
            toast.warn(error.response.data.message);
          } else if (error.response && error.response.status === 500) {
            toast.error(
              "Nossos servidores não estão em bom momento! Tente novamente mais tarde"
            );
          } else {
            toast.error("Verifique sua conexão");
          }
          return Promise.reject(error);
        }
      );

      const res = await instance.request({
        url: url,
        data: options.data,
        method: options.method,
      });
      setData(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);
  return { data, error, isLoading, reFetch: fetchData };
};

class Fetch {
  createInstance = async (
    options = { defaultTakeCareError: true, handleUploadProgress: undefined }
  ) => {
    const token = localStorage.getItem("token");

    const headers = {
      token: token || "",
    };

    const uploadProgress = (progressEvent) => {
      if (!!options.handleUploadProgress) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        options.handleUploadProgress(percentCompleted);
      }
    };

    const instance = axios.create({
      baseURL: baseUserURL,
      timeout: 50000,
      headers,
      onUploadProgress: !!options.handleUploadProgress
        ? uploadProgress
        : undefined,
    });

    if (!!options.defaultTakeCareError)
      instance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            toast.error("Sessão expirada. Faça login novamente");
            localStorage.setItem("token", "");
            localStorage.setItem("user", "");
            window.location.href = `${window.location.origin}/login`;
          } else if (error.response && error.response.status === 403) {
            toast.warn("Usuário não tem permissão");
          } else if (error.response && error.response.status === 429) {
            toast.warn("Muitas requisições ao mesmo tempo");
          } else if (error.response && error.response.status === 400) {
            toast.warn(error.response.data.message);
          } else if (error.response && error.response.status === 500) {
            toast.error(
              "Nossos servidores não estão em bom momento! Tente novamente mais tarde"
            );
          } else {
            toast.error("Verifique sua conexão");
          }
          return Promise.reject(error);
        }
      );

    return instance;
  };

  createInstanceToken = (token) => {
    const headers = {
      token: token || "",
    };

    return axios.create({
      baseURL: baseUserURL,
      timeout: 50000,
      headers
    });
  }

  get = async (url, options = { defaultTakeCareError: true }) => {
    return (await this.createInstance(options)).get(url);
  };
  post = async (
    url,
    data,
    options = { defaultTakeCareError: true, handleUploadProgress: undefined }
  ) => {
    return (await this.createInstance(options)).post(url, data);
  };
  delete = async (url) => {
    return (await this.createInstance()).delete(url);
  };
  put = async (url, data) => {
    return (await this.createInstance()).put(url, data);
  };
  patch = async (url, data) => {
    return (await this.createInstance()).patch(url, data);
  };
  postToken = async (
    url,
    data,
    token
  ) => {
    return (await this.createInstanceToken(token)).post(url, data);
  };
}


export { useFetch };

export default new Fetch();
