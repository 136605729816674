import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import withTracker from "./withTracker";
import routes from "./routes";
import themes from "./themes";
import SimpleLayout from "./layouts/SimpleLayout";
import icons from "@paljs/icons";
import { Layout } from "@paljs/ui/Layout";
import { ToastContainer } from "react-toastify";
import SettingsContext, { SettingsProvider } from "./components/Settings";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./pages/NotFound";

const Initial = (props) => {
  const { settings } = React.useContext(SettingsContext);

  const token = "tesw";

  return (
    <ThemeProvider theme={themes(settings.theme)}>
      <SimpleLayout />
      <Layout evaIcons={icons}>
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={withTracker((props) => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  })}
                  render={({ component: Component, ...otherProps }) => (
                    <Component {...otherProps} />
                  )}
                />
              );
            })}
          </Switch>
        </Router>
        <ToastContainer />
      </Layout>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <SettingsProvider>
      <Initial />
    </SettingsProvider>
  );
};

export default App;
